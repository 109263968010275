import React, { useEffect, useMemo, useRef, useState } from 'react';
import './Stayhost.scss'
import { useTranslation } from "react-i18next";
import { stayhostCols } from "../../constants/tableColumns";
import ListTable from "../Lists/ListTable/ListTable";
import { getManualStayhost, getStayhost } from "../../http/pricingAPI";
import ellipsisSvg from "../../images/ellipsis.svg";
import { Button, Modal } from "@mui/material";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import { formatDateOptions } from "../../utils/formatDate";
import checkSvg from "../../images/check.svg";
import PdfListTemplate from "./components/PdfListTemplate";

const Stayhost = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const modalExportRef = useRef(null);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [selectedExport, setSelectedExport] = useState('CSV');
  const [manualLoading, setManualLoading] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getStayhost();
      if (result && result.status === 200) {
        console.log("stayhost data: ", result.data);
        setData(result.data.stayhosts);
      } else {
        setData([]);
      }
    };
    
    fetchData().then(() => setLoading(false));
  }, []);
  
  const handleManualUpdate = async () => {
    setManualLoading(true);
    const result = await getManualStayhost();
    
    if (result && result.status === 200) {
      console.log("manual update data: ", result.data);
      alert('ClickUp data successfully updated manually');
      setManualLoading(false);
    } else {
      setManualLoading(false);
    }
  };
  
  const columns = useMemo(() => stayhostCols.map((item) => {
    if (item.header) {
      return { ...item, header: t(item.header.toLowerCase()) };
    }
    return item
  }), [t]);
  
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setModalPosition({ top: e.clientY + 10, left: e.clientX - 100 });
    setIsOpen(true);
  };
  
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose(e);
      }
      if (modalExportRef.current && !modalExportRef.current.contains(e.target)) {
        setIsOpenExport(false);
      }
    };
    
    if (isOpen || isOpenExport) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isOpenExport]);
  
  const handleOpenExport = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    try {
      handleClose(e);
      setIsOpenExport(true);
    } catch (error) {
      console.log("handleOpenExport error: ", error);
    }
  };
  
  const handleExport = (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (selectedJobs && selectedJobs.rows && selectedJobs.rows.length > 0) {
        const rows = selectedJobs.rows;
        const fileName = `Stayhosts_${formatDateOptions(new Date())}`;
        if (selectedExport === 'CSV') {
          const csvConfig = mkConfig({
            fieldSeparator: ',',
            decimalSeparator: '.',
            useKeysAsHeaders: true,
            fileName: `${fileName}`
          });
          const rowData = rows.map((row) => row.original);
          const csv = generateCsv(csvConfig)(rowData);
          download(csvConfig)(csv);
        } else if (selectedExport === 'PDF') {
          const doc = new jsPDF();
          const input = document.getElementById('pdfListTemplate');
          
          doc.html(input, {
            callback: function (doc) {
              doc.save(`${fileName}.pdf`);
            },
            html2canvas: { scale: 0.35 }
          })
        }
      }
    } catch (error) {
      console.log("handleExport error: ", error);
    }
  }
  
  return (
    <div className="stayhost">
      <div className="stayhost__title_wrap">
        <h2 className="stayhost__title">{`${t('stayhost')}`}</h2>
        <div className="breadcrumbs__add_menu">
          <button
            className={manualLoading ? 'loading' : ''}
            onClick={handleManualUpdate}
            disabled={manualLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 30 30"
            >
              <path
                d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"
              />
            </svg>
          </button>
          <img
            src={ellipsisSvg}
            alt="ellipsis"
            onClick={handleOpen}
          />
        </div>
        <Modal
          open={isOpen}
          onClose={(e) => handleClose(e)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className={"breadcrumbsModal__modal"}
        >
          <div
            className={"breadcrumbsModal__content"}
            style={{
              top: `${modalPosition.top + 10}px`,
              left: `${modalPosition.left - 285}px`,
            }}
          >
            <div className={"breadcrumbsModal__options_title"}>
              <span>Options</span>
            </div>
            <div className={"breadcrumbsModal__buttons"}>
              <Button
                className={"breadcrumbsModal__button"}
                onClick={handleOpenExport}
              >
                <span>{t('exportList')}</span>
              </Button>
            </div>
          </div>
        </Modal>
        <div className={`modal ${(isOpenExport) ? 'active' : ''}`}>
          <div className={`modalRef export`} ref={modalExportRef}>
            <div className="modal__close" onClick={() => {
              setIsOpenExport(false);
            }}
            >
              <svg className="close_icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M22.0712 22.0709L7.9291 7.92881" stroke="#268664" strokeLinecap="round" />
                <path d="M22.0709 7.92881L7.92876 22.0709" stroke="#268664" strokeLinecap="round" />
              </svg>
            </div>
            <div className="modal__title brdc">
              <h2 className="text">{t('exportList')}</h2>
            </div>
            {
              isOpenExport ? (
                <div className="modal__select_buttons">
                  <button
                    className={`modal__select_button_item ${selectedExport === 'CSV' ? 'active' : ''}`}
                    onClick={() => setSelectedExport('CSV')}
                  >
                    <img src={checkSvg} alt="check" />
                    <span>CSV</span>
                  </button>
                  <button
                    className={`modal__select_button_item ${selectedExport === 'PDF' ? 'active' : ''}`}
                    onClick={() => setSelectedExport('PDF')}
                  >
                    <img src={checkSvg} alt="check" />
                    <span>PDF</span>
                  </button>
                </div>
              ) : (
                <div>
                </div>
              )
            }
            {
              isOpenExport ? (
                <div className="modal__buttons">
                  <button
                    className={`btn cancel`}
                    onClick={() => setIsOpenExport(false)}
                  >
                    {t('cancel')}
                  </button>
                  <button
                    className={`btn`}
                    onClick={(e) => handleExport(e)}
                  >
                    {t('export')}
                  </button>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="data stayhost">
          <div className="data__table-wrap">
            <ListTable
              data={data}
              columns={columns}
              isClickable={true}
              isSelectable={true}
              onSelectItems={setSelectedJobs}
              isJob={true}
            />
          </div>
        </div>
        )
      }
      {
        selectedJobs &&
        selectedJobs.rows &&
        selectedJobs.rows.length > 0 ? (
          <PdfListTemplate data={selectedJobs.rows} />
        ) : null
      }
    </div>
  );
};

export default Stayhost;